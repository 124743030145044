import { useNavigation } from "@remix-run/react";
import { cn } from "~/lib/utils";
import { Loader2 } from "lucide-react";

function GlobalLoading() {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  return (
    <>
      {/* Progress Bar */}
      <div
        role="progressbar"
        aria-valuetext={active ? "Loading" : undefined}
        aria-hidden={!active}
        className={cn(
          "pointer-events-none fixed inset-x-0 top-0 z-50 h-1 transition-all duration-300 ease-out",
          active ? "opacity-100" : "opacity-0"
        )}
      >
        <div className="h-full w-full animate-loading-bar bg-primary" />
      </div>

      {/* Loading Overlay */}
      <div
        className={cn(
          "pointer-events-none fixed inset-0 z-40 bg-background/80 backdrop-blur-sm transition-all duration-300",
          active ? "opacity-100" : "opacity-0"
        )}
      >
        <div className="flex h-full items-center justify-center">
          <div className="flex flex-col items-center gap-2">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
            <p className="text-sm text-muted-foreground animate-pulse">
              {navigation.state === "loading" ? "Loading..." : "Submitting..."}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

// Loading Spinner Component
function LoadingSpinner({ className }: { className?: string }) {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      <Loader2 className="h-4 w-4 animate-spin text-primary" />
    </div>
  );
}

// Loading Skeleton Component
function LoadingSkeleton({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "animate-pulse rounded-md bg-muted",
        className
      )}
    />
  );
}

// Loading Card Component
function LoadingCard() {
  return (
    <div className="rounded-lg border bg-card p-4 shadow-sm">
      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <LoadingSkeleton className="h-12 w-12 rounded-full" />
          <div className="space-y-2">
            <LoadingSkeleton className="h-4 w-32" />
            <LoadingSkeleton className="h-3 w-24" />
          </div>
        </div>
        <div className="space-y-2">
          <LoadingSkeleton className="h-3 w-full" />
          <LoadingSkeleton className="h-3 w-5/6" />
        </div>
      </div>
    </div>
  );
}

// Loading Table Component
function LoadingTable() {
  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <LoadingSkeleton className="h-8 w-32" />
        <LoadingSkeleton className="h-8 w-24" />
      </div>
      <div className="rounded-md border">
        <div className="border-b bg-muted/50 p-2">
          <div className="grid grid-cols-4 gap-4">
            {[...Array(4)].map((_, i) => (
              <LoadingSkeleton key={i} className="h-4" />
            ))}
          </div>
        </div>
        {[...Array(5)].map((_, i) => (
          <div key={i} className="border-b p-2">
            <div className="grid grid-cols-4 gap-4">
              {[...Array(4)].map((_, j) => (
                <LoadingSkeleton key={j} className="h-4" />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { 
  GlobalLoading, 
  LoadingSpinner, 
  LoadingSkeleton, 
  LoadingCard,
  LoadingTable 
};
