/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
// External Libraries
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
} from "@remix-run/react";
import { LoaderFunction, LoaderFunctionArgs } from "@remix-run/node";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// eslint-disable-next-line import/no-named-as-default
import clsx from "clsx";
import { ClientOnly } from "remix-utils/client-only";
import LogRocket from "~/components/controls/logrocket";

// CSS and Themes
import "./tailwind.css";
import { PreventFlashOnWrongTheme, ThemeProvider, useTheme } from "remix-themes";

// Internal Modules
import { themeSessionResolver, getSession } from "~/server/session.server";
import { getEnv } from "~/server/env.server";
import { getDomainUrl } from "~/lib/utils";
import { getDeviceCategory, getHints } from "~/lib/client-hints";
import { SessionProvider } from "~/contexts/auth";
import { GlobalLoading } from "~/components/loading";
import { AppRoutes } from "./lib/routes";

// Fonts
// Supports weights 100-900
import '@fontsource-variable/inter';
// Supports weights 100-900
import '@fontsource-variable/dm-sans';
import '@fontsource/barlow';
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";


const queryClient = new QueryClient()

// const fontFamily = 'DM Sans Variable, sans-serif';
const fontFamily = 'Barlow, sans-serif';


export const loader: LoaderFunction = async ({ request }: LoaderFunctionArgs) => {
  const { getTheme } = await themeSessionResolver(request);
  const session = await getSession(request.headers.get("cookie"));

  const user = session.get("user");
  const organisations = user?.profiles?.map(
    (profile: any) => profile.organisation
  );

  return {
    LOGROCKET_APP_ID: "ctrlv-solutions/medlink",
    theme: getTheme(),
    ENV: getEnv(),
    session: {
      user: user,
      profile: session.get("profile") || user?.profile,
      organisations: organisations,
    },
    requestInfo: {
      hints: getHints(request),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
      deviceKind: getDeviceCategory(request)
    },
  }
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <ThemeProvider
      specifiedTheme={data.theme || 'light'}
      themeAction="/action/set-theme"
    >
      <SessionProvider session={data.session}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </SessionProvider>
    </ThemeProvider>
  );
}

// Loader Function
// export const loader: LoaderFunction = async (args: LoaderFunctionArgs) => {
//   return rootAuthLoader(args, async ({ request }) => {
//     const { sessionId, userId, getToken } = request.auth;

//     // Retrieve theme and environment variables
//     const { getTheme } = await themeSessionResolver(request);

//     return {
//       theme: getTheme(),
//       ENV: getEnv(),
//     };
//   });
// };

// Application with Theme and Providers
export default withSentry(AppWithProviders);

// Main Application Component
export function App() {
  const data = useLoaderData<typeof loader>();
  const [theme] = useTheme();
  const hints = data.requestInfo.hints;
  const preferredColorScheme = hints.theme

  useEffect(() => {
    const profile = data.session.profile;

    if (profile?.profile_type === "CLIENT") {
      var Tawk_API: any = Tawk_API || {};
      var Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/67769aaaaf5bfec1dbe5d167/1il9qohgq';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0?.parentNode?.insertBefore(s1, s0);
      })();
    }
  }, []);

  return (
    <html lang="en" className={clsx(theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
        <Links />
      </head>
      <body style={{ fontFamily }}>
        <GlobalLoading />
        <Outlet />
        <ScrollRestoration />
        <ClientOnly>
          {() => <LogRocket appId={data.LOGROCKET_APP_ID || ""} />}
        </ClientOnly>
        <Toaster />
        <Scripts />
      </body>
    </html>
  );
}

// Error Boundary Component
export function ErrorBoundary() {
  const error: any = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body style={{ fontFamily }}>
        <section className="flex items-center h-full p-16 dark:bg-gray-50 dark:text-gray-800">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
            <div className="max-w-md text-center">
              <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-400">
                <span className="sr-only">Error</span>{isRouteErrorResponse(error) ? error?.status : "500"}
              </h2>
              <p className="text-2xl font-semibold md:text-3xl">{isRouteErrorResponse(error)
                ? "Sorry, we couldn't find this page."
                : error instanceof Error
                  ? error.message
                  : "Unknown Error"}
              </p>
              <p className="mt-4 mb-8 dark:text-gray-600">But dont worry, you can find plenty of other things on our homepage.</p>
              <a rel="noopener noreferrer" href={AppRoutes.Dashboard.Index} className="px-8 py-3 font-semibold bg-primary rounded dark:bg-violet-600 dark:text-gray-50">Back to homepage</a>
            </div>
          </div>
        </section>
        <Scripts />
      </body>
    </html>
  );
}

// Export Default App with Clerk Integration
// export default ClerkApp(AppWithProviders);